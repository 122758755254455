<template>
  <div class="home">
    <el-carousel class="banner" ref="carousel0" :interval="5000" height="460px">
      <el-carousel-item v-for="item in banner" :key="item.id">
        <img class="banner-item" :src="item.image" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="block1 block">
      <div v-if="data.image" class="img-news">
        <el-carousel
          ref="carousel1"
          :interval="5000"
          indicator-position="none"
          @change="handleCarouselChange"
        >
          <el-carousel-item v-for="item in data.image.articles" :key="item.id">
            <img
              class="image-news-item"
              :src="item.cover_image"
              alt=""
              @click="handleToArticle(item.id)"
            />
          </el-carousel-item>
        </el-carousel>
        <div class="title">
          {{ data.image.articles[activeIndex] && data.image.articles[activeIndex].title || '' }}
          <div class="indicators">
            <div
              class="indicator"
              :class="{ active: activeIndex === index }"
              v-for="(item, index) in data.image.articles"
              :key="index"
              @click="handleIndicatorClick(index)"
            ></div>
          </div>
        </div>
      </div>
      <div class="family-news">
        <div class="block-header">
          <div class="title">家族资讯</div>
          <a
            v-if="data.family"
            class="more"
            href="javascript: void(0)"
            @click="$router.push(`/web/article?cat_id=${data.family.id}`)"
            >更多</a
          >
        </div>
        <div v-if="data.family" class="block-content">
          <a
            v-for="item in data.family.articles"
            :key="item.id"
            class="list-item"
            href="javascript: void(0)"
            @click="handleToArticle(item.id)"
          >
            <span class="content">{{ item.title }}</span>
            <span class="date"
              >[{{ $format(item.cdatetime, "YYYY-MM-DD") }}]</span
            >
          </a>
        </div>
      </div>
      <div class="side-func verticle-block">
        <div class="btns">
          <a class="btn" href="/web/search" target="_blank">
            <img src="@/assets/images/index/btn1.png" alt="" class="icon" />
            <div class="text">查谱</div>
          </a>
          <a
            class="btn"
            href="http://xp.mmjp5000.com"
            target="_blank"
          >
            <img src="@/assets/images/index/btn2.png" alt="" class="icon" />
            <div class="text">修谱</div>
          </a>
        </div>
        <div class="announce">
          <div class="block-header">
            <div class="title">公告通知</div>
            <a
              v-if="data.notice"
              class="more"
              href="javascript: void(0)"
              @click="$router.push(`/web/article?cat_id=${data.notice.id}`)"
              >更多</a
            >
          </div>
          <div v-if="data.notice" class="block-content">
            <a
              v-for="item in data.notice.articles"
              :key="item.id"
              class="list-item"
              href="javascript: void(0)"
              @click="handleToArticle(item.id)"
            >
              <span class="content">{{ item.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="block2 block">
      <div class="famous">
        <div class="block-header">
          <div class="title">姓氏名人</div>
          <div class="tabs">
            <div
              class="tab"
              :class="{ active: activeTabIndex === index }"
              v-for="(tab, index) in tabs"
              :key="tab.value"
              @mouseover="activeTabIndex = index"
            >
              {{ tab.label }}
            </div>
          </div>
        </div>
        <div v-if="data[tabs[activeTabIndex].value]" class="block-content">
          <a
            v-for="item in data[tabs[activeTabIndex].value].articles"
            :key="item.id"
            class="list-item"
            href="javascript: void(0)"
            @click="handleToArticle(item.id)"
          >
            <img class="thumb" :src="item.cover_image" alt="" />
            <span class="content">
              <div class="title">
                <span>{{ item.title }}</span>
                <span class="tag">{{ item.tags }}</span>
              </div>
              <div class="desc">{{ item.description }}</div>
            </span>
          </a>
        </div>
      </div>
      <div class="notice-block verticle-block">
        <div class="notice">
          <div class="block-header">
            <div class="title">项目发布</div>
            <a
              v-if="data.finance"
              class="more"
              href="javascript: void(0)"
              @click="$router.push(`/web/article?cat_id=${data.finance.id}`)"
              >更多</a
            >
          </div>
          <div v-if="data.finance" class="block-content">
            <a
              v-for="item in data.finance.articles"
              :key="item.id"
              class="list-item"
              href="javascript: void(0)"
              @click="handleToArticle(item.id)"
            >
              <span class="content">{{ item.title }}</span>
            </a>
          </div>
        </div>
        <div class="download">
          <div class="block-header">
            <div class="title">下载专区</div>
            <a
              v-if="data.download"
              class="more"
              href="javascript: void(0)"
              @click="$router.push(`/web/article?cat_id=${data.download.id}`)"
              >更多</a
            >
          </div>
          <div v-if="data.download" class="block-content">
            <a
              v-for="item in data.download.articles"
              :key="item.id"
              class="list-item"
              href="javascript: void(0)"
              @click="handleToArticle(item.id)"
            >
              <span class="content">{{ item.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="block3 block">
      <div class="chuancheng">
        <div class="block-header">
          <div class="title">传承精英</div>
          <a
            v-if="data.jingying"
            class="more"
            href="javascript: void(0)"
            @click="$router.push(`/web/article?cat_id=${data.jingying.id}`)"
            >更多</a
          >
        </div>
        <el-carousel
          v-if="data.jingying"
          class="chuancheng-swiper"
          ref="carousel2"
          :interval="5000"
          height="300px"
        >
          <el-carousel-item
            v-for="item in chunk(data.jingying.articles, 5)"
            :key="item.id"
          >
            <div class="block-content">
              <a
                v-for="subItem in item"
                :key="subItem.id"
                class="list-item"
                href="javascript: void(0)"
                @click="handleToArticle(subItem.id)"
              >
                <img class="thumb" :src="subItem.cover_image" alt="" />
                <div class="title">{{ subItem.title }}</div>
                <div class="tags">{{ subItem.tags }}</div>
              </a>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- <div class="block4 block">
      <div class="link">
        <div class="block-header">
          <div class="title">友情链接</div>
        </div>
        <div class="block-content">
          <a
            v-for="item in links"
            :key="item.value"
            class="list-item"
            :href="item.value"
            target="_blank"
          >
            <span class="content">{{ item.label }}</span>
          </a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui";
import indexApis from "@/apis/index";
import otherApis from "@/apis/other";

function chunk(array, chunkSize) {
  if (!array) return [];
  var R = [];
  for (var i = 0; i < array.length; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
}

export default {
  name: "Home",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  data() {
    return {
      activeIndex: 0,
      activeTabIndex: 0,
      data: {},
      banner: [],
      tabs: [
        {
          label: "文化名流",
          value: "wenhua"
        },
        {
          label: "军事将帅",
          value: "junshi"
        },
        {
          label: "科技院士",
          value: "keji"
        },
        {
          label: "商界领袖",
          value: "shangjie"
        },
        {
          label: "政坛精英",
          value: "zhengtan"
        }
      ],
      links: [
        {
          label: "名门修谱",
          value: "www.mmxp.com"
        },
        {
          label: "名门述源",
          value: "www.shuyuan99.com"
        },
        {
          label: "御龙刘氏",
          value: "www.ylliushi.com"
        }
      ]
    };
  },
  created() {
    this.fetchList();
    this.fetchBanner();
  },
  methods: {
    chunk,
    handleCarouselChange(index) {
      this.activeIndex = index;
    },
    handleIndicatorClick(index) {
      this.$refs.carousel1.setActiveItem(index);
    },
    async fetchList() {
      this.loading = true;
      try {
        const {
          data: { result }
        } = await indexApis.indexGroup({
          cat_key:
            "image, family, notice, wenhua, junshi, keji, shangjie, zhengtan,  finance, jingying, download"
        });
        this.data = result;
      } catch (error) {
        // this.finished = true;
        this.error = true;
        console.error(error);
      }
      this.loading = false;
    },
    async fetchBanner() {
      console.log("fetch banner");
      const {
        data: { banners }
      } = await otherApis.banner();

      this.banner = banners;
      console.log(banners);
    },
    handleToArticle(id) {
      this.$router.push(`/web/article?id=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 1200px;
  position: relative;
  margin: 0 auto;
  // height: calc(100vh - 70px);

  .block {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // background-color: #ddd;
  }

  .verticle-block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .block-header {
    height: 46px;
    width: 100%;
    color: #333;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    box-sizing: border-box;
    border-bottom: 1px solid #ededed;

    .title {
      line-height: 46px;
      display: inline-flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 19px;
        background: #ca0a15;
        // line-height: 46px;
        position: relative;
        top: -1px;
        margin-right: 10px;
      }
    }

    .more {
      color: #8f8f8f;
      font-size: 14px;
      line-height: 46px;

      &::after {
        content: ">";
      }
    }

    &.opacity {
      opacity: 0.54;
    }
  }

  .block-content {
    height: calc(100% - 46px);
    width: 100%;
    padding: 5px 15px;
    box-sizing: border-box;
    .list-item {
      color: #333;
      font-size: 14px;
      line-height: 32px;
      white-space: nowrap;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content {
        display: inline-block;
        width: calc(100% - 56px);
        overflow: hidden;
        text-overflow: ellipsis;

        &::before {
          content: "‧";
          line-height: 32px;
          display: inline-block;
        }
      }

      .date {
        color: #8f8f8f;
        float: right;
      }
    }
  }

  .banner {
    height: 460px;
    width: 100%;
    background-color: #ddd;

    .banner-item {
      height: 460px;
      width: 100%;
      object-fit: cover;
    }
  }

  .block1 {
    .img-news {
      height: 330px;
      width: 330px;
      position: relative;

      .image-news-item {
        height: 286px;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      .title {
        position: absolute;
        bottom: 0;
        height: 44px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;
        box-sizing: border-box;
        // padding-right: 92px;
      }

      .indicators {
        display: flex;
        align-items: center;

        .indicator {
          height: 10px;
          width: 10px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          margin-right: 6px;
          cursor: pointer;

          &:last-of-type {
            margin-right: 0;
          }

          &.active {
            background: #ca0a15;
            border-radius: 50%;
          }
        }
      }
    }

    .family-news {
      width: 500px;
      background-color: #fff;
      height: 330px;

      .block-header {
        background-color: #e2e2e2;
      }
    }

    .side-func {
      height: 330px;
      width: 330px;

      .btns {
        height: 128px;
        width: 330px;
        background-color: #fff;
        display: flex;
        justify-content: space-evenly;

        .btn {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-evenly;
          align-items: center;

          .icon {
            height: 70px;
            width: 70px;
          }

          .text {
            font-size: 14px;
            color: #333;
          }
        }
      }

      .announce {
        height: 186px;
        width: 330px;
        background-color: #fff;
      }
    }
  }

  .block2 {
    .famous {
      height: 480px;
      width: 850px;
      background-color: #fff;

      .block-header {
        background-color: #e2e2e2;
      }

      .tabs {
        display: flex;
        align-items: flex-end;
        height: 100%;

        .tab {
          position: relative;
          bottom: -1px;
          width: 104px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          transition: background 0.3s ease;
          cursor: pointer;

          &.active {
            background-color: #fff;
          }
        }
      }

      .block-content {
        padding: 20px;
        display: flex;
        flex-flow: row wrap;
        height: auto;
        max-height: calc(100% - 46px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        // justify-content: flex-start;
        // align-items: flex-start;
      }

      .list-item {
        height: 120px;
        width: 395px;
        flex: none;
        background-color: #f1f1f1;
        margin-left: 20px;
        margin-bottom: 20px;

        .thumb {
          width: 90px;
          height: 120px;
          object-fit: cover;
          flex: none;
        }

        .content {
          padding: 10px;
          box-sizing: border-box;

          &::before {
            display: none;
          }

          .title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 31px;
            display: flex;
            align-items: center;

            span {
              max-width: 65%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .tag {
              font-size: 14px;
              font-weight: bold;
              color: #666666;
              line-height: 31px;
              margin-left: 20px;
              max-width: 35%;
            }
          }

          .desc {
            font-size: 12px;
            color: #333333;
            line-height: 22px;
            height: 66px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }

    .notice {
      height: 188px;
      width: 330px;
      background-color: #fff;
    }

    .download {
      height: 276px;
      width: 330px;
      background-color: #fff;

      .block-header {
        // height: 108px;
        align-items: flex-end;
      }
    }
  }

  .block3 {
    .chuancheng {
      width: 1200px;
      height: 346px;
      background-color: #fff;

      .chuancheng-swiper {
        height: calc(100% - 46px);
      }

      .block-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;

        .list-item {
          flex: none;
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          flex-flow: column nowrap;
          // height: 194px;
          .thumb {
            height: 194px;
            width: 160px;
            object-fit: cover;
          }

          .title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }

          .tags {
            font-size: 14px;
            color: #666666;
            margin-top: -10px;
          }
        }
      }
    }
  }

  .block4 {
    margin-bottom: 30px;
    .link {
      width: 1200px;
      height: 110px;
      background-color: #fff;

      .block-content {
        display: flex;
        flex-flow: row wrap;

        .list-item {
          width: auto;
          display: inline-flex;
          .content {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
